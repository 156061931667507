.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  font-size: 40px !important;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background: unset;
  color: #000;
  text-decoration: none;
}
