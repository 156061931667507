html,
body {
  font-family: "Open Sans", sans-serif;
}

.page-box {
  min-height: 11rem;
  border: 1px solid #27aeef;
  border-radius: 8px;
  background-color: #043673;
  padding: 10px;
  text-align: center;
}

.page-box-title {
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
}

.useful-links img {
  max-height: 100px;
}

.pointer {
  cursor: pointer;
}

.map {
  width: 100%;
  height: 550px;
  background-color: #f4fcff;
}

.project-card {
  border-radius: 8px 8px 0px 0px;
  transition: 618ms;
  cursor: pointer;
}

.hero-banner {
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
}

.header-title {
  margin-bottom: 60px;
}

.project-card .project-body {
  border: 1px solid #e0e0e0;
  min-height: 200px;
  height: calc(100% - 200px);
}

.project-card .project-imgbox {
  border-radius: 8px 8px 0px 0px;
  height: 165px;
  position: relative;
  background-size: cover;
}

.project-card:hover,
.project-card:focus {
  transform: scale(1.02);
  transition: 200ms;
  box-shadow: 0 0 30px #ccc;
}

.w-md-50 {
  width: 100%;
}

@media (min-width: 1400px) {
  .w-md-50 {
    width: 60%;
  }

  .hero-banner {
    background-size: contain;
  }
}

.project-card .project-imgbox .project-status {
  width: 20px;
  height: 20px;
  /* UI Properties */
  position: absolute;
  top: 14px;
  right: 10px;
  border: 3px solid var(--white);
  /* background: #538249 0% 0% no-repeat padding-box; */
  border: 3px solid #ffffff;
  border-radius: 18px;
}

.project-card .project-imgbox .project-restoration {
  width: 20px;
  height: 20px;
  /* UI Properties */
  position: absolute;
  top: 11px;
  right: 36px;
}

.project-card .project-imgbox .project-star {
  width: 20px;
  height: 20px;
  /* UI Properties */
  position: absolute;
  top: 11px;
  left: 10px;
}

.project-card .project-imgbox .project-regions {
  position: absolute;
  left: 6px;
  bottom: 4px;
}

.projects-more-regions {
  bottom: 0px;
  position: absolute;
  top: 163px;
  left: 5px;
  background-color: white;
  height: fit-content;
}

.bottom-menu {
  background-color: #000;
  min-height: 25px;
}

.project-badge-more {
  display: block;
  background: #00aeef 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin: 4px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.top-menu {
  font-weight: bold;
  font-size: 14px;
}

.project-card .project-imgbox .project-badge {
  background: #00aeef 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin: 4px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  width: fit-content;
}

.box-shadow {
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
}

.project-card .project-imgbox-filter {
  background: transparent linear-gradient(180deg, #001e432c 0%, #001836 100%) 0%
    0% no-repeat padding-box;
  height: 165px;
  border-radius: 8px 8px 0px 0px;
}

.project-imgbox-filter {
  background: transparent linear-gradient(180deg, #001e432c 0%, #001836 100%) 0%
    0% no-repeat padding-box;
}

.project-card .project-footer {
  background-color: #e0edff;
  border: 2px solid #e0edff;
  border-radius: 0px 0px 8px 8px;
}

.title-display-0 {
  font: normal normal 600 50px/68px Open Sans;
  font-weight: normal;
}

.title-display-5 {
  color: #212529;
  font-size: 14px;
}

.title-display-3 {
  color: #414141;

  font: normal normal bold 12px/16px Open Sans;
}

.title-display-2 {
  color: #003476;
  font: normal normal bold 18px/24px Open Sans;
}

.title-display-1-24 {
  font: normal normal 600 24px/24px Open Sans;
  color: #212529;
}

.title-display-1 {
  font: normal normal 600 30px/41px Open Sans;
  color: #212529;
}

.title-display-4 {
  color: #414141;
  font: normal normal 600 11px/24px Open Sans;
}

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  bottom: -28px;
}

.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  right: 10px;
  bottom: -25px;
}

.owl-theme .owl-dots .owl-dot span {
  margin: 5px 3px !important;
  width: 12px !important;
  height: 12px !important;
  background: #e6e6e6 !important;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #00aeef !important;
}

.owl-theme .owl-nav [class*="owl-"] {
  font-size: 22px !important;
}

.owl-next {
  background-color: #003476;
}

.project-highlight-card {
  position: relative;
  background-size: cover;
  height: 476px;
  border-radius: 16px;
  box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.35) !important;
}

.project-highlight-card .project-highlight-badge {
  font: normal normal 600 14px/33px Open Sans;
  color: #ffffff;
  border-radius: 8px;
  top: 20px;
  left: 20px;
  position: absolute;
}

.highlight-title-display-1 {
  color: #ffffff;
  font: normal normal bold 28px/33px Open Sans;
  /* font: normal normal bold 22px/33px Open Sans; */
}

.highlight-title-display-4 {
  color: #ffffff;
  font-size: 14px;
  font-weight: unset;
}

.highlight-title {
  position: absolute;
  bottom: 20px;
}

.owl-stage-outer {
  height: 500px !important;
}

.project-highlight-imgbox-filter {
  position: relative;
  background-size: cover;
  height: 476px;
  background: transparent linear-gradient(180deg, #001e432c 0%, #001836 100%) 0%
    0% no-repeat padding-box;
  border-radius: 16px;
}

footer {
  margin-top: 20px;
  background: #003476 0% 0% no-repeat padding-box;
  /* height: 10rem; */
}

.search-label {
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #212529;
}

.content-text {
  font: normal normal normal 18px/26px Open Sans;
  color: #212529;
}

.detail-text-tags {
  font: normal normal 600 22px/30px Open Sans;
  color: #212529;
}

.detail-text-label {
  font: normal normal 600 16px/22px Open Sans;
  color: #8f9193;
}

.detail-title {
  font: normal normal bold 38px/54px Open Sans;
  color: #ffffff;
}

.detail-card {
  background: #041a34a3 0% 0% no-repeat padding-box;
  border-radius: 12px;
}

.detail-card .card-label {
  font: normal normal 600 14px/19px Open Sans;
  color: #ffffff;
}

.detail-card .card-text {
  font: normal normal bold 32px/40px Open Sans;

  color: #ffffff;
}

.detail-card .card-badge {
  background: #538249 0% 0% no-repeat padding-box;
  border-radius: 8px;
}

.detail-site-title {
  font: normal normal 600 20px/27px Open Sans;
}

.detail-back-to-list {
  font: normal normal bold 12px/43px Open Sans;
  color: #ffffff;
}

.bg-image {
  background: transparent linear-gradient(180deg, #001e432c 0%, #001836 100%) 0%
    0% no-repeat padding-box;
}

.bg-blue {
  background-color: #043673;
  color: #fff !important;
  border: 4px solid #043673;
  border-top: 0px;
}

.site-menu .nav-link {
  color: #fff !important;
}

a {
  text-decoration: none;
}

.select-status {
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
}

.text-green {
  color: #538249;
}

.text-orange {
  color: #ad6330;
}

.text-purple {
  color: #694a9c;
}

.searchWrapper {
  max-height: 38px;
  overflow-y: auto;
  overflow-x: hidden;
}

.map-switcher-label {
  font: normal normal 600 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #212529;
}

.total {
  font: normal normal 600 18px/24px Open Sans;
  letter-spacing: 0px;
  color: #212529;
}

.advanced-filter {
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #212529;
  opacity: 1;
}

.advanced-filter:hover {
  text-decoration: underline;
  cursor: pointer;
}

.filter-label {
  width: 100%;
}

.filter-label button {
  width: 100%;
}

.rmdp-input {
  border: 0px !important;
}

.rmdp-input:focus {
  box-shadow: none !important;
  outline: none !important;
}

.highlighted .project-body {
  border: 4px solid #27aeef;
  border-top: 0px;
  /* border: 4px solid 
  border-radius: 8px; */
}

.highlighted .project-footer {
  background-color: #27aeef;
  color: #fff !important;
  border: 4px solid #27aeef;
  border-top: 0px;
  /* border: 4px solid 
  border-radius: 8px; */
}

.highlighted .project-footer .title-display-5 {
  color: #fff !important;
}

.rmdp-container {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-link {
  text-decoration: none;
}
